import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/byggnadssmide/byggnadssimde-main.jpg";
import img_1 from '../images/byggnadssmide/byggnadssmide_2.jpg';
import img_2 from '../images/byggnadssmide/byggnadssmide_3.jpg';
import img_3 from '../images/byggnadssmide/byggnadssmide_4.jpg';
import img_4 from '../images/byggnadssmide/byggnadssmide_5.jpg';
import img_5 from '../images/byggnadssmide/byggnadssmide_6.jpg';
import img_6 from '../images/byggnadssmide/byggnadssmide_7.jpg';
import favicon from '../images/favicon.ico';

function Byggnadssmide() {
    return (
        <Layout>
            <Helmet>
                <title>Byggnadssmide - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi utför all typ av byggnadssmide i Södertälje med certifikat C2 och C3 klass svetsning" />
                <meta name="keywords" content="plåt, smide, Byggnadssmide" />
                <meta property="og:title" content="Byggnadssmide - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/byggnadssmide" />
                <link rel="cannonical" href="https://danasplat.se/byggnadssmide" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-byggnadssmide bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Byggnadssmide</h1>
                    </div>
                </div>
                <Breadcrumb page="Byggnadssmide"/>
            </section>

            <section className='relative'>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Experter på byggnadssmide</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Vi utför all typ av byggnadssmide med certifikat C2 och C3 klass svetsning.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Vi är experter på byggnadssmide och våra uppdrag utförs enligt kundens önskemål.  Vi jobbar med allt ifrån stålstommar till trappräcken, balkongräcken, pelare och inbrottsgaller.</p>
                                <p className="lg:text-xl text-gray-600 mt-6 font-bold">ISO-certifieringar</p>
                                <p className="lg:text-xl text-gray-600">- ISO 1090</p>
                                <p className="lg:text-xl text-gray-600">- ISO 3834</p>
                                <p className="lg:text-xl text-gray-600">- ISO 9001</p>
                                <p className="lg:text-xl text-gray-600">- ISO 14001</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Välkommen att kontakta oss!</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Byggnadssmide" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr className=""/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-24">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="Byggnadssmide i Södertälje" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="Svetsa smide" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="Bygga Byggnadssmide" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                                <img alt="skapa smide" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_4} />
                                <img alt="Bygga smide i Stockholm" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_5} />
                                <img alt="reparera byggnadssmide" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_6} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Byggnadssmide;